import * as React from 'react'
import styled from '@emotion/styled'
import AdSense from 'react-adsense'

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface LayoutMainProps {
  className?: string
}

const LayoutMain: React.SFC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className}>
    {children}
    <AdSense.Google client="ca-pub-4614359726897771" slot="885275079" />
  </StyledLayoutMain>
)

export default LayoutMain
