import * as React from 'react'
import { Link } from 'gatsby'
import { IPageContent } from '../typings'

interface IPaginationProps {
  pageContext: IPageContent
  pathPrefix: string
}

interface IPaginationState {}

export class Pagination extends React.PureComponent<IPaginationProps, IPaginationState> {
  constructor(props: any) {
    super(props)

    this.state = {}
  }

  render() {
    const { pageContext } = this.props
    const { previousPagePath, nextPagePath } = pageContext

    return (
      <nav className="pagination" role="navigation">
        <ul className="pagination">
          <li className="page-item">
            {previousPagePath && (
              <Link to={previousPagePath} rel="prev" className="float-left">
                <button className="btn btn-primary btn-sm paging-button">Previous</button>
              </Link>
            )}
            &nbsp;
          </li>
          {nextPagePath && (
            <li className="page-item">
              <Link to={nextPagePath} rel="next" className="float-right">
                <button className="btn btn-primary btn-sm paging-button">Next</button>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}
