import * as React from 'react'
import { Link } from 'gatsby'

/*
  <footer className="navbar footer fixed-bottom footer-light footer-shadow content container-fluid">
    <nav className="navbar navbar-light fixed-bottom navbar-expand-sm footer">
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto" />
      </div>

    </nav>
  </footer>
  */
const Footer: React.SFC = () => (
  <footer className="bg-light mt-4">
    <div className="container-fluid py-6">
      <div className="row">
        <div className="col-md-4">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/terms/">
                Terms
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/privacy/">
                Privacy
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-4 small text-center align-self-center d-none d-lg-block d-xl-block">
          Factual rankings via{' '}
          <a href="https://mediabiasfactcheck.com" target="_blank">
            Media Bias/Fact Check
          </a>
        </div>
        <div className="col-md-4 text-right small align-self-center">
          &copy; {new Date().getFullYear()} <Link to="/">factualsearch.news</Link>, All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
