import * as React from 'react'
import { Link } from 'gatsby'
import AdSense from 'react-adsense'

import Container from '../components/Container'

interface INewsListProps {
  posts: any[]
  title: string
}

interface INewsListState {}

export class NewsList extends React.PureComponent<INewsListProps, INewsListState> {
  render() {
    const { posts, title } = this.props
    let count = 0

    return (
      <Container>
        <h1>{title}</h1>
        <p>
          The following news items appear on the <Link to="https://mediabiasfactcheck.com">Media Bias/Fact Check</Link> website:
        </p>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <div className="card">
                <div className="card-body">
                  <h3>
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                </div>
              </div>
              {++count % 5 === 0 && <AdSense.Google client="ca-pub-4614359726897771" slot="885275079" />}
            </div>
          )
        })}
      </Container>
    )
  }
}
