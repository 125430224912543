import * as React from 'react'
import { graphql } from 'gatsby'

import IndexLayout from '../layouts'
import StyledPage from '../components/StyledPage'
import Container from '../components/Container'
import { Pagination } from '../components/Pagination'
import { INewsPage, IPageContent } from '../typings'
import { NewsList } from '../components/NewsList'

interface NewsPageTemplateProps {
  pageContext: IPageContent
  data: {
    allMarkdownRemark: {
      edges: INewsPage[]
    }
  }
}

class NewsPageTemplate extends React.PureComponent<NewsPageTemplateProps> {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <IndexLayout>
        <StyledPage>
          <Container>
            <NewsList posts={posts} title="Latest MBFC News" />
            <Pagination pageContext={pageContext} pathPrefix="/" />
          </Container>
        </StyledPage>
      </IndexLayout>
    )
  }
}

export const newsFields = graphql`
  fragment newsFields on MarkdownRemark {
    fields {
      slug
    }
    htmlAst
    excerpt(format: HTML, truncate: true)
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      original
      news
    }
  }
`

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { news: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...newsFields
        }
      }
    }
  }
`

export default NewsPageTemplate
