import * as React from 'react'
import styled from '@emotion/styled'
import 'jquery'
import 'bootstrap'

import '../scss/gatstrap.scss'

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
`

interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.SFC<LayoutRootProps> = ({ children, className }) => (
  <>
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </>
)

export default LayoutRoot
